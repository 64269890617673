<template>
  <div class="row">
    <div class="col">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <Confirm ref="confirm" :method="store"/>
      <transition name="slide">
        <div class="card border-warning">
          <form ref="frmNuevo" novalidate>
            <div class="card-header">
              <strong class="text-primary">
                Registro de Materia para el docente
              </strong>
              <div class="card-header-actions">
                <button @click="$refs.modalVideo.mostrarVideo('video-alumno-nuevo.mp477777777777777777')"
                        class="btn btn-outline-info" title="Ver video de ayuda">
                  <font-awesome-icon icon="fas fa-question-circle"/>
                  Ayuda
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-3">
                  <label class="form-label" for="gestion">Gestión:</label>
                  <select class="form-control pl-1 pr-1" id="gestion"
                          v-model="docente.gestion" required="required"
                          @change="estudiantes_inscritos=0"
                  >
                    <option value="" selected>:: SELECCIONAR ::</option>
                    <template v-for="(option,key) in gestiones">
                      <optgroup :label="key">
                        <option v-for="suboption in option" :value="suboption.value">
                          {{ suboption.label }}
                        </option>
                      </optgroup>
                    </template>
                  </select>
                </div>
                <div class="col-3">
                  <CSelect
                      label="Facultad"
                      :value.sync="docente.facultad_id"
                      :plain="true"
                      :options="facultades"
                      @change="docente.carrera_id=''; estudiantes_inscritos=0"
                      required="required"
                  >
                  </CSelect>
                </div>
                <div class="col-3">
                  <label for="alumnoCarrera">Carrera</label>
                  <select v-if="docente.facultad_id" id="alumnoCarrera" required="required"
                          v-model="docente.carrera_id" class="form-control"
                          @change="actualizaMaterias($event), estudiantes_inscritos=0">
                    <option value="">:: SELECCIONAR ::</option>
                    <option v-for="option in carreras[docente.facultad_id]" :value="option.value">{{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col-3 text-center">
                  <CSelect
                      :options="planes"
                      :plain="true"
                      :value.sync="docente.plan_id"
                      label="Plan de Estudios"
                      required="required"
                      @change="estudiantes_inscritos=0"
                  >
                  </CSelect>
                </div>
              </div>
              <hr/>
              <template v-if="mostrarMaterias">
                <div class="row">
                  <div class="col-2">
                    <label for="alumnoCarrera">Semestre</label>
                    <select id="semestre" required="required"
                            v-model="semestre" class="form-control"
                            @change="estudiantes_inscritos=0">
                      <option value="0">:: SELECCIONAR ::</option>
                      <option v-for="(semestres, index3) in materias" :value="index3">Semestre {{ index3 }}
                      </option>
                    </select>
                  </div>
                  <div class="col-5">
                    <label for="alumnoCarrera">Materia</label>
                    <select v-if="semestre!=''" id="materia_id" required="required"
                            v-model="docente.materia_id" class="form-control"
                            @change="actualizarEstudiantesInscritos">
                      <option value="0">:: SELECCIONAR ::</option>
                      <option v-for="option3 in materias[semestre]" :value="option3.id">{{ option3.sigla }} -
                        {{ option3.materia }}
                      </option>
                    </select>
                  </div>
                  <div class="col-2">
                    <label for="alumnoCarrera">Grupo</label>
                    <select v-if="semestre!==''" id="grupo" required="required"
                            v-model="docente.grupo" class="form-control"
                            @change="actualizarEstudiantesInscritos">
                      <template v-for="option4 in materias[semestre]">
                        <option v-if="option4.id === docente.materia_id" v-for="option5 in option4.grupos"
                                :value="option5">{{ option5 }}
                        </option>
                      </template>
                    </select>
                  </div>
                  <div class="col-3">
                    <label>Estudiantes Inscritos <small class="text-info">(hasta la fecha)</small></label>
                    <input class="form-control-plaintext text-center text-bold" :value="estudiantes_inscritos"/>
                  </div>
                </div>
              </template>
              <div v-if="this.materias.length<1" class="row">
                <div class="col  text-center text-danger">
                  <strong>No existen materias para mostrar</strong>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="button" class="btn btn-primary mr-2" @click="store()">Grabar</button>
              <button type="button" class="btn btn-danger" @click="goBack">Regresar a la pantalla anterior</button>
            </div>
          </form>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalVideo from "@/views/notifications/ModalVideo";
import Confirm from "@/views/notifications/Confirm";

var moment = require('moment');
var modalPdf = null;

export default {
  name: 'EditUser',
  components: {Confirm, ModalVideo, Alerts, Toast},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      materias: [], gestiones: [],
      grupos: [], grupo_semestre: [],
      facultades: [], carreras: [],
      planes: [], materiaPorDefecto: ":: SELECCIONAR ::",
      semestre: "0",
      docente: {
        facultad_id: 0,
        carrera_id: 0,
        plan_id: 0,
        gestion: '',
        traspaso_id: 0,
        sede_id: localStorage.sedeSeleccionada,
        materia_id: 0,
        grupo: ":: SELECCIONAR ::",
        id: 0,
      },
      maxdate: moment(new Date()).add(-12 * 10, 'M').format("YYYY-MM-DD"),
      estudiantes_inscritos: 0
    }
  },
  methods: {
    goBack() {
      this.$router.push({path: '/docentemateria/' + this.$route.params.id});
    },
    store() {
      if (!modalPdf.$refs.frmNuevo.checkValidity()) {
        modalPdf.$refs.frmNuevo.classList.add('was-validated');
        modalPdf.$refs.mensajeToast.makeToast('', 'Algunos datos son requeridos.', 'danger');
      } else {
        modalPdf.$refs.alert.show('Grabando materias asignadas');
        axios.post(this.$apiAdress + '/api/docentemateria/' + this.$route.params.id + '/store?token=' + localStorage.getItem("api_token"),
            modalPdf.docente
        )
            .then(function (response) {
              modalPdf.$refs.alert.hide();
              modalPdf.docente.materia_id = 0;
              modalPdf.docente.grupo = ":: SELECCIONAR ::"
              modalPdf.docente.id = 0
              modalPdf.estudiantes_inscritos=0
              modalPdf.$refs.mensajeToast.makeToast('', 'Registro creado satisfactoriamente.', 'success');
            })
            .catch(function (error) {
              modalPdf.$refs.alert.hide();
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
    actualizaMaterias(event) {
      if (modalPdf.docente.plan_id > 0 && modalPdf.docente.carrera_id > 0) {
        axios.get(this.$apiAdress + '/api/docentemateria/' + modalPdf.docente.plan_id + '/' + modalPdf.docente.carrera_id + '/0/materias?token=' + localStorage.getItem("api_token"))
            .then(function (response) {
              modalPdf.materias = response.data.materias;
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
    actualizarEstudiantesInscritos(event) {
      if (modalPdf.docente.gestion !== '' && modalPdf.docente.materia_id > 0 && modalPdf.docente.grupo > 0) {
        axios.get(this.$apiAdress + '/api/docentemateria/' + modalPdf.docente.gestion + '/' + modalPdf.docente.materia_id + '/' + modalPdf.docente.grupo + '/estudiantesinscritos?token=' + localStorage.getItem("api_token"))
            .then(function (response) {
              modalPdf.estudiantes_inscritos = response.data.estudiantes_inscritos;
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
  },
  computed: {
    mostrarMaterias() {
      if (this.docente.plan_id !== '' && this.docente.plan_id > 0) {
        this.actualizaMaterias();
        return true;
      }
      this.materias = [];
      return false;
    }
  },
  mounted: function () {
    modalPdf = this;
    modalPdf.docente.sede_id = localStorage.sedeSeleccionada;
    axios.get(this.$apiAdress + '/api/docentemateria/' + localStorage.sedeSeleccionada + '/parametrosmaterias?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.facultades = valorPorDefecto.concat(response.data.facultades);
          modalPdf.carreras = response.data.carreras;
          modalPdf.planes = valorPorDefecto.concat(response.data.planes);
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
    axios.post(this.$apiAdress + '/api/factura/' + localStorage.sedeSeleccionada + '/opcionesfactura?token=' + localStorage.getItem("api_token"),
        {'tipo_lista': 'docente_lista'}
    )
        .then(function (response) {
          modalPdf.gestiones = response.data.gestiones;
          modalPdf.docente.gestion = response.data.mesActual;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}

</script>
<style>
.table td div.form-group {
  margin-bottom: 0px !important;
}
</style>